<template>
  <TransitionRoot :show="openCombobox" as="template" @after-leave="search=''" appear>
    <Dialog as="div" class="relative z-full" @close="openCombobox = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/20 backdrop-blur-sm transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox v-slot="{ activeOption }" @update:modelValue="onSelect">
              <div class="relative">
                <Loading class="animate-spin pointer-events-none absolute top-3.5 left-4 h-5 w-5" v-if="loading" />
                <MagnifyingGlassIcon class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" aria-hidden="true" v-else />
                <ComboboxInput class="outline-none h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm" :placeholder="getLocaleString(searchPlaceholder)" @change="searchQuery" />
              </div>

              <ComboboxOptions v-if="results?.length > 0" static class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800" hold>
                <ComboboxOption @click="openCombobox=false" v-for="item in results" :key="item.url" :value="item" as="template" v-slot="{ active, selected }" class="p-3">
                  <NuxtLinkLocale :to="checkAndAddSlash(item.url)" class="flex space-x-2" :class="{'bg-gray-100':active}">
                    <NuxtImg
                        :src="getImage(item.thumbnail)"
                        :alt="item.modelSku"
                        fit="cover"
                        width="40"
                        height="40"
                        class="h-10 w-10 object-cover"
                    />

                    <div class="flex flex-col truncate">
                      <div class="block truncate" :class="{'font-medium' : selected, 'text-secondary' : active}">
                        {{ item.modelSku }} - {{ item.productTitle }}
                      </div>
                      <div class="text-gray-400">{{ $t('widgets.search.n-option', item.variantCount, [item.variantCount]) }}</div>
                    </div>
                  </NuxtLinkLocale>
                </ComboboxOption>
              </ComboboxOptions>

              <p v-if="loading" class="p-4 text-sm text-gray-500">{{ $t('widgets.search.loading') }}</p>
              <p v-else-if="results?.length === 0 && !loading" class="p-4 text-sm text-gray-500">>{{ $t('widgets.search.no-result') }}</p>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {computed, ref, useStoreConfig} from '#imports'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import Loading from "~/assets/icons/loading.svg"

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

const storeConfig = useStoreConfig()
const {baseConfig} = storeToRefs(storeConfig)

const loading = useState('searchLoading', () => false)
const results = ref()
const search = useState('navSearchQuery')
const openCombobox = useState('openCombobox')
const debounce = ref()

const searchPlaceholder = baseConfig.value?.search_placeholder;
const searchQuery = async (e) => {

  if (!e.target.value) {
    return;
  }

  clearTimeout(debounce.value)
  results.value = []

  debounce.value = await setTimeout(() => {
    search.value = e.target.value;
  }, 600)

  loading.value = true

  results.value = await $fetch(`/api/search`, {
    method: 'GET',
    query: {
      query : e.target.value
    },
  })

  loading.value = false;
}

function onSelect(product) {
  openCombobox.value = false;
  navigateTo(product.url)
}

watch(openCombobox,()=>{
  if(openCombobox.value===false){
    search.value='';
    results.value=[];
  }
})
</script>